<script setup>
import { useHomeStore } from '~/stores/home.store';
import { ref } from 'vue';
import { useSettingsStore } from '~/stores/settings.store';
import DesktopListingList from '~/pages/desktop/listings/index.vue';
import { useSsrFetch } from "../composables/useFetch";
import { useRoute } from 'vue-router';
import ShowTeammate from '../teammates/show.vue';
import BlogCategory from '~/pages/desktop/blog/index.vue';
import BlogPost from '~/pages/desktop/blog/show.vue';
import DesktopPageDetails from '~/pages/desktop/pages/show.vue';

const route = useRoute();
const result = ref({});

const fetchData = async () => {
	const qs = btoa(JSON.stringify(route.query));
	const slug = btoa(JSON.stringify(route.params.slug));
	return useSsrFetch(`pages/slug?slug=${slug}&qs=${qs}`);
}

result.value = await fetchData();

if (!result.value?.model) {
	showError({
		statusCode: 404,
		statusMessage: "Page Not Found"
	});
}
</script>
<template>

	<template v-if="result?.model === 'PageCategory'">
	</template>

	<template v-else-if="result?.model === 'Page'">
		<DesktopPageDetails :meta="result?.meta" :data="result?.data" :fetchData="fetchData" />
	</template>

	<template v-else-if="result?.model === 'Teammate'">
		<ShowTeammate :meta="result?.meta" :data="result?.data" :fetchData="fetchData"></ShowTeammate>
	</template>

	<template v-else-if="result?.model === 'BlogCategory'">
		<BlogCategory :meta="result?.meta" :data="result?.data" :fetchData="fetchData" />
	</template>

	<template v-else-if="result?.model === 'BlogPost'">
		<BlogPost :meta="result?.meta" :data="result?.data" />
	</template>

	<template v-else-if="result?.model === 'ListingList'">
		<DesktopListingList :data="result?.data" :fetchData="fetchData" />
	</template>

</template>
